import 'toasted-notes/src/styles.css';
import callApi from '_utils/callApi';
import { getFormData } from '_utils';
import toaster from 'toasted-notes';
import store from 'store';

import { message } from 'antd';

export async function loginUserWithOTP(values) {
  console.log('values', values);
  try {
    //   const formData = getFormData(values);
    //   console.log("formDatta",formData)
    const options = {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization':'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJpYXQiOjE2MDQ2MzY1MTAsImV4cCI6MTk2OTYzNjUxMH0.nZ1k-azTjiy1joYV0T8zyLnwtt3mCZt-QoEORNZRjIE'
      },
    };
    const response = await callApi('/api/backend/v1/user/login/otp/verify', options);
    console.log('a response', response);
    if (response && response.token) {
      setToken(response.token);
      toaster.notify('Logged In Successfully', { position: 'top-right' });
      setTimeout(() => {
        window.location.href = '/';
      }, 2000);
    }
    // return null;
  } catch (error) {
    console.log('error', error);
    toaster.notify(error.message, { position: 'top-right' });
    return { error: error.message };
  }
  return {};
}
const setToken = token => {
  store.set('token', token);
};
const setUser = user => {
  store.set('User', user);
};

export async function loginUserWithPassword(values) {
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization':'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJpYXQiOjE2MDQ2MzY1MTAsImV4cCI6MTk2OTYzNjUxMH0.nZ1k-azTjiy1joYV0T8zyLnwtt3mCZt-QoEORNZRjIE'
      },
    };
    const response = await callApi('/api/backend/v1/login', options);
    console.log('USER-response---->>>>>>', response);
    if (response && response.token) {
      setToken(response.token);
      message.success('Logged In Successfully');
      setTimeout(() => {
        window.location.href = '/';
      }, 2000);
    }
    return null;
  } catch (error) {
    // console.log('error', error);
    // message.error(error.message);
    return { error: error.message };
  }
  return {};
}

export async function sendOTP(phone) {
  console.log('values', phone);
  try {
    //   const formData = getFormData(values);
    //   console.log("formDatta",formData)
    const options = {
      method: 'GET',
      // body: JSON.stringify(values),
      // headers: {
      //     'Content-Type': 'application/json',
      //     // 'Authorization':'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJpYXQiOjE2MDQ2MzY1MTAsImV4cCI6MTk2OTYzNjUxMH0.nZ1k-azTjiy1joYV0T8zyLnwtt3mCZt-QoEORNZRjIE'
      //   },
    };
    const a = await callApi(`/api/backend/v1/user/login/mvalidate/${Number(phone)}`, options);
    console.log('a response', a);
    if (a && a.data) return a.data;
    return null;
  } catch (error) {
    console.log('error', error);
    toaster.notify(error.message, { position: 'top-right' });
    return { error: error.message };
  }
  return {};
}
export async function registerUser(values) {
  try {
    //   const formData = getFormData(values);
    //   console.log("formDatta",formData)
    const options = {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization':'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJpYXQiOjE2MDQ2MzY1MTAsImV4cCI6MTk2OTYzNjUxMH0.nZ1k-azTjiy1joYV0T8zyLnwtt3mCZt-QoEORNZRjIE'
      },
      // body:formData
    };
    const a = await callApi('/api/backend/v1/user/signup', options);
    console.log('a response', a);
    if (a && a.data) return a.data;
    return null;
  } catch (error) {
    console.log('error', error);
    toaster.notify(error.message, { position: 'top-right' });
    return { error: error.message };
  }
  return {};
}

export async function loginUserWithGoogle(response) {
  console.log(response);
  if (response && response.profileObj) {
    try {
      var { email: loginId, name, googleId: id, imageUrl: profileImage , name: username} = response.profileObj;

      const formData = getFormData({
        loginId,
        name,
        id,
        profileImage,
        roleId: 2,
        firebaseToken: response.accessToken,
        username
      });
      //   console.log("formDatta",formData)
      const options = {
        method: 'POST',
        body: formData,
      };
      const result = await callApi('/api/backend/v1/auth/google', options);
      if (result && result.token) {
        setToken(result.token);
        setUser(result.data);
        window.localStorage.setItem('loginType',result.data.loginType);
       message.success('Logged In Successfully');
        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
        // return true;
      }
      return null;
    } catch (error) {
      // console.log('error', error);
      toaster.notify(error.message, { position: 'top-right' });
      return { error: error.message };
    }
  }
  return {};
}

export async function loginUserWithFacebook(response) {
  console.log(response);

  if (response && response.email) {
    try {
      var {
        email: loginId,
        name,
        id,
        picture: {
          data: { url: profileImage },
        },
        name :username
      } = response;

      const formData = getFormData({
        loginId,
        name,
        id,
        profileImage,
        roleId: 2,
        firebaseToken: response.accessToken,
        username
      });
      //   console.log("formDatta",formData)
      const options = {
        method: 'POST',
        body: formData,
      };
      const result = await callApi('/api/backend/v1/auth/facebook', options);
      console.log('a response', result);
      if (result && result.token) {
        setToken(result.token);
        window.localStorage.setItem('loginType',result.data.loginType);

        message.success('Logged In Successfully');
        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
        // return true;
      }
    } catch (error) {
      console.log('error', error);
      message.error(error.message);
      return { error: error.message };
    }
  } else {
    message.error('Please log into this webpage');
  }
}

import callApi from "_utils/callApi";

export const getNotifications = async () => {
    try {
      const result = await callApi(`/api/backend/v1/notifications?limit=40&page=1`, {
        method: 'GET',
      });
      return result
    } catch (err) {
      console.log(err);
    }
  };
import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import Footer from '../pages/Footer';
import Header from '../pages/Header';
import Login from '../pages/Login';
import {getNotifications} from "../services/getNotficationCount";

export const PublicRoute = ({ component: Component, path, keys, exact }) => {
  const [modal, setmodal] = useState(false);
  const [scroll, setscroll] = useState(0);
  useEffect(() => {
      getNotficationCount();
    window.scrollTo({
      top: 80,
      left: 100,
      behavior: 'smooth',
    });
  }, [scroll]);

    const [messageCount , setMessageCount] = useState(0)

    const getNotficationCount = async () => {
        try {
            const res = await getNotifications();
            if(Object.keys(res).length > 0) {
                // console.log('UN-READ--->>>', res.unreadMessages);
                setMessageCount(res.unreadMessages)
            } else {
                setMessageCount(0)
            }
        } catch (err) {
            console.log(err);
        }
    };
  return (
    <Route
      path={path}
      key={keys}
      exact={exact}
      render={props => {
        return (
          <div
            className="main_container"
            style={{
              width: '100%',
              overflowX: 'hidden',
            }}
          >
            <Header {...props} setmodal={setmodal} modal={modal} />
            <div>
              <Component setmodal={setmodal} modal={modal} {...props} />
            </div>
              {path !== '/message' && (
                  <Footer setscroll={setscroll} messageCount={messageCount} />
              )}
            {modal && <Login setmodal={setmodal} onClose={setmodal} />}
          </div>
        );
      }}
    />
  );
};

import React, { Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, BrowserRouter } from 'react-router-dom';
import './index.css';
import 'antd/dist/antd.css';
import './css/main.css';
import { createStore, StoreProvider, action } from 'easy-peasy';
import Router from 'navigation/Router';
import { ValidateToken } from '_utils/validatetoken';
// import { getCurrentLocation } from '_utils/googleApi';
// import { message } from 'antd';
import { Provider } from 'react-redux';
import { store } from './reducers/configureStore';

import callApi from '_utils/callApi';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}

export const Foodcan = React.createContext();

const App = () => {
  const [status, setstatus] = useState(false);
  const [userinfo, setuserinfo] = useState(null);
  const [loading, setloading] = useState(true);
  const [city, setcity] = useState(localStorage.getItem('address') || '');

  useEffect(() => {
    const validate = async () => {
      try {
        const result = await ValidateToken();

        if (result[0]) {
          setstatus(result[0]);
          // setuserinfo(result[1]);
          let getUserDetails = await callApi(`/api/backend/v1/get/user/${result[1].user_id}`, {
            method: 'GET',
          });
          setuserinfo({
            profileImage: getUserDetails.data.profileImage,
            ...result[1],
          });
        } else {
          setstatus(result[0]);
        }

        setloading(false);
      } catch (err) {
        console.log(err);
      }
    };
    validate();
  }, []);

  if (loading) {
    return (
      <div className="loadingdiv">
        <i class="fa fa-spinner fa-spin"></i>
      </div>
    );
  }

  return (
    <Provider store={store}>
      <Foodcan.Provider
        value={
          status
            ? {
                status,
                ...userinfo,
                setstatus,
                city,
                setcity,
              }
            : { status, setstatus, city, setcity }
        }
      >
        <Suspense
          fallback={
            <div className="loadingdiv">
              <i class="fa fa-spinner fa-spin"></i>
            </div>
          }
        >
          <BrowserRouter>
            <Router result={status} />
          </BrowserRouter>
        </Suspense>
      </Foodcan.Provider>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

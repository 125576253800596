import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MenuOutlined, CloseOutlined, DownOutlined } from '@ant-design/icons';
import { Select, Menu, Dropdown } from 'antd';
import { GoogleComponent } from '../../components/Map_Location/src/GoogleComponent1';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Foodcan } from 'index';
import EllipsisText from 'react-ellipsis-text';
import NotifiacationPanel from 'pages/Notification';
import { getNotifications } from 'services/getNotficationCount';
import { isMobile } from 'react-device-detect';
import Login from 'pages/Login';
const headermenu_list = [
  {
    title: 'HOME',
    path: '/',
    wologin: true,
    wlogin: true,
  },
  {
    title: 'POSTED ITEMS',
    path: '/listing',
    wologin: true,
    wlogin: true,
  },
  {
    title: 'ADD FREEBIE/NEEDS',
    path: '/addlisting',
    wologin: false,
    wlogin: true,
  },
  {
    title: 'LOG IN',
    path: '/login',
    className: 'loginbutton',
    wologin: true,
    wlogin: false,
  },
  {
    title: 'MY PROFILE',
    path: '/profile',
    wologin: false,
    wlogin: true,
  },
  {
    title: 'DASHBOARD',
    path: '/user-dashboard',
    wologin: false,
    wlogin: true,
  },
  {
    title: 'MY LISTING',
    path: '/mylisting',
    wologin: false,
    wlogin: true,
  },
  {
    title: 'MY REVIEWS',
    path: '/user-reviews',
    wologin: false,
    wlogin: true,
  },
  {
    title: 'LOG OUT',
    path: '',
    wologin: false,
    wlogin: true,
  },
];

export default function Homepage_Header({ setmodal, modal, history }) {
  const [active, setActive] = useState('/');
  const [width, setWidth] = useState(window.innerWidth);
  const [link, setLink] = useState(false);
  const [place, setplace] = useState(null);
  const [isHide, setHide] = useState(false);
  const [login, setLogin] = useState(false);

  const { status, username, firstName, profileImage } = React.useContext(Foodcan);

  // this is part of notification
  const menu = (
    <Menu
      style={{
        width: '300px',
        maxHeight: '500px',
        overflow: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div>
        <NotifiacationPanel />
      </div>
    </Menu>
  );

  const [notifiactionCount, setNotiicationCount] = useState(0);
  const [messageCount, setMessageCount] = useState(0);

  const getNotficationCount = async () => {
    try {
      const res = await getNotifications();
      // console.log("notii-->>>>>>>>",res.unreadMessages);
      if (Object.keys(res).length > 0) {
        setNotiicationCount(res.data.count);
        setMessageCount(res.unreadMessages);
      } else {
        setNotiicationCount(0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const concernedElement = document.querySelector(".header");

    document.addEventListener("mousedown", (event) => {
      if (concernedElement.contains(event.target)) {
        console.log("Clicked Inside");
      } else {
        setLink(false);
      }
    });
  }, []);

  useEffect(() => {
    getNotficationCount();
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  return (
    <>
      <div className="header" style={isHide ? { top: -110 } : { top: 0 }}>
        <div className="header__main">
          <div className="header__logolink">
            <div className="header__logo">
              {!link ? (
                <MenuUnfoldOutlined
                  onClick={() => setLink(!link)}
                  className="header__menu"
                  style={{ color: 'black', fontSize: 25 }}
                />
              ) : (
                <MenuFoldOutlined
                  onClick={() => setLink(!link)}
                  className="header__menu"
                  style={{ color: 'var(--primarys)', fontSize: 25 }}
                />
              )}
              <a onClick={() => history.push('/')}>
                food<span>can</span>
              </a>
            </div>
            <div
              className={`header__linkgroup ${
                width > 1000 ? 'header__desktop_display' : 'header__mobile__display'
              }`}
              style={{
                display: width < 1000 && link ? 'flex' : '',
              }}
            >
              {status
                ? headermenu_list
                    .filter(result => result.wlogin == true)
                    .map((result, i) => {
                      if (
                        result.title === 'DASHBOARD' ||
                        result.title === 'MY LISTING' ||
                        result.title === 'MY REVIEWS'
                      ) {
                        return (
                          <a
                            key={i.toString()}
                            onClick={() => history.push(result.path)}
                            className="header__linkgroup__link mobile_display_only"
                            style={{
                              backgroundColor: width < 1000 ? 'white' : '',
                              display: width > 1000 ? 'none' : 'inline-block',
                            }}
                          >
                            {result.title}
                          </a>
                        );
                      } else if (result.title === 'NOTIFICATIONS') {
                        return (
                          <Dropdown overlay={menu} trigger={['click']}>
                            <a
                              className="header__linkgroup__link"
                              onClick={e => e.preventDefault()}
                            >
                              <i class="fa fa-bell" aria-hidden="true"></i>
                              <DownOutlined />
                            </a>
                          </Dropdown>
                        );
                      } else if (result.title === 'LOG OUT') {
                        return (
                          <a
                            onClick={() => {
                              localStorage.removeItem('token');
                              window.location.href = '/';
                            }}
                            className="header__linkgroup__link mobile_display_only"
                            style={{
                              backgroundColor: width < 1000 ? 'white' : '',
                              display: width > 1000 ? 'none' : 'inline-block',
                            }}
                          >
                            {result.title}
                          </a>
                        );
                      } else {
                        return (
                          <Link
                            to={result.path}
                            onClick={() => setActive(result.title)}
                            className={`header__linkgroup__link`}
                            style={{ backgroundColor: width < 1000 ? 'white' : '' }}
                          >
                            {result.title}
                          </Link>
                        );
                      }
                    })
                : headermenu_list
                    .filter(result => result.wologin == true)
                    .map((result, i) => {
                      if (result.title === 'LOG IN') {
                        return (
                          <Link
                            onClick={() => setmodal(true)}
                            className="header__linkgroup__link loginbutton"
                            style={{ backgroundColor: width < 1000 ? 'white' : '' }}
                          >
                            {result.title}
                          </Link>
                        );
                      } else if (result.title === 'HOME') {
                        return (
                          <a
                            onClick={() => (window.location.href = '/')}
                            className="header__linkgroup__link"
                            style={{ backgroundColor: width < 1000 ? 'white' : '' }}
                          >
                            {result.title}
                          </a>
                        );
                      } else if (result.title === 'MY PROFILE') {
                        return (
                          <a
                            onClick={() => history.push('/profile')}
                            className="header__linkgroup__link"
                            style={{ backgroundColor: width < 1000 ? 'white' : '' }}
                          >
                            {result.title}
                          </a>
                        );
                      } else {
                        return (
                          <Link
                            to={result.path}
                            onClick={() => setActive(result.title)}
                            className={`header__linkgroup__link`}
                            style={{ backgroundColor: width < 1000 ? 'white' : '' }}
                          >
                            {result.title}
                          </Link>
                        );
                      }
                    })}
              {/* {headermenu_list.map((result, i) => {
                if (result.title === 'LOG IN') {
                  return (
                    <Link
                      to={result.path}
                      onClick={() => setActive(result.title)}
                      className="header__linkgroup__link loginbutton"
                      style={{ backgroundColor: width < 1000 ? 'white' : '' }}
                    >
                      {result.title}
                    </Link>
                  );
                } else if (result.title === 'HOME') {
                  return (
                    <a
                      onClick={() => (window.location.href = '/')}
                      className="header__linkgroup__link"
                      style={{ backgroundColor: width < 1000 ? 'white' : '' }}
                    >
                      {result.title}
                    </a>
                  );
                } else {
                  return (
                    <Link
                      to={result.path}
                      onClick={() => setActive(result.title)}
                      className={`header__linkgroup__link`}
                      style={{ backgroundColor: width < 1000 ? 'white' : '' }}
                    >
                      {result.title}
                    </Link>
                  );
                }
              })} */}
            </div>
          </div>
          <div className="header__right">
            {status && (
              <Dropdown className="notification" overlay={menu} trigger={['click']}>
                {/*<a  onClick={e => e.preventDefault()}>*/}
                {/*  <i class="fa fa-bell" aria-hidden="true"></i>*/}
                {/*  {notifiactionCount && (*/}
                {/*    <span className="badge">{notifiactionCount}</span>*/}
                {/*  )}*/}

                {/*</a>*/}
                <a onClick={e => e.preventDefault()} className="notification1">
                  <i className="fa fa-bell" aria-hidden="true"></i>
                  <span className="badge">{notifiactionCount ? notifiactionCount : 0}</span>
                </a>
              </Dropdown>
            )}

            {/*{status && !isMobile && (
              <Dropdown className="notification" trigger={['click']}>
                <a onClick={e => e.preventDefault()}>
                  <i class="fa fa-bell" aria-hidden="true"></i>
                  {notifiactionCount && <span className="badge">{notifiactionCount}</span>}
                </a>
                <a onClick={e => (window.location.href = '/message')} className="notification1">
                  <i className="fa fa-comment-dots" aria-hidden="true"></i>
                  {messageCount !== 0 && <span className="badge">{messageCount}</span>}
                </a>
              </Dropdown>
            )}*/}

            {!status ? (
              // <button onClick={() => setmodal(true)} className="right__button">
              //   <i className="fa fa-user"></i>LOG IN
              // </button>
              <button onClick={() => setLogin(true)} className="right__button">
                <i className="fa fa-user"></i>LOG IN
              </button>
            ) : (
              <button onClick={() => history.push('/profile')} className="right__button">
                <img
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50px',
                    marginRight: '10px',
                  }}
                  src={profileImage ? profileImage : 'https://www.gravatar.com/avatar/?d=mp'}
                />
                <EllipsisText text={username ? username.toUpperCase() : ''} length={'10'} />
              </button>
            )}
            <GoogleComponent
              apiKey={'AIzaSyAqu6fqJ9JMsC83CNtkOje2X-KylbDnoss'}
              language={'en'}
              country={'country:in|country:bt|country:np|country:pk|country:af'}
              coordinates={true}
              //   locationBoxStyle={'custom-style'}
              locationListStyle={'custom-style-list'}
              onChange={e => setplace(e)}
              placeholder="Location"
            />
          </div>
          {login && <Login setmodal={setLogin} onClose={value => setLogin(value)} />}
        </div>
      </div>
    </>
  );
}

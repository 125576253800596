import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Index as NotFoundPage } from '../components/404';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { withRouter } from 'react-router';

const loadable = loader => React.lazy(loader); // added

const routes = [
  // System Pages
  {
    path: '/',
    component: loadable(() => import('../pages/Homepage')),
    exact: true,
    authorize: false,
  },
  {
    path: '/signup',
    component: loadable(() => import('../pages/Login')),
    exact: true,
    authorize: false,
  },
  {
    path: '/addlisting',
    component: loadable(() => import('../pages/Listing/Add/index')),
    exact: true,
    authorize: true,
  },
  {
    path: '/message',
    component: loadable(() => import('../pages/Message/index')),
    exact: true,
    authorize: false,
  },
  {
    path: '/editlisting',
    component: loadable(() => import('../pages/Listing/Edit/index')),
    exact: true,
    authorize: true,
  },
  {
    path: '/listing',
    component: loadable(() => import('../pages/Listing/index')),
    exact: true,
    authorize: false,
  },{
    path: '/user-dashboard',
    component: loadable(() => import('../pages/user-dashboard/statistics')),
    exact: true,
    authorize: true,
  },
  {
    path: '/mylisting',
    component: loadable(() => import('../pages/user-dashboard/mylisting')),
    exact: true,
    authorize: true,
  },
  {
    path: '/user-reviews',
    component: loadable(() => import('../pages/user-dashboard/myreviews')),
    exact: true,
    authorize: true,
  },
  {
    path: '/profile',
    component: loadable(() => import('../pages/user-dashboard/myprofile')),
    exact: true,
    authorize: true,
  },
  {
    path: '/contact-us',
    component: loadable(() => import('../pages/contactUs/index')),
    exact: true,
    authorize: false,
  },
  {
    path: '/terms-of-service-agreement',
    component: loadable(() => import('../pages/TermsAndServices/index')),
    exact: true,
    authorize: false,
  },
  {
    path: '/reset-password/:id',
    component: loadable(() => import('../pages/Login/forgotpassword')),
    exact: true,
    authorize: false,
  },

  {
    path: '/privacy-and-policy',
    component: loadable(() => import('../pages/Privacypolicy')),
    exact: true,
    authorize: false,
  },
  {
    path: '/:id',
    component: loadable(() => import('../pages/ProductDetailsPage/index')),
    exact: true,
    authorize: false,
  },

];

const Router = props => {
  const { history, result } = props;
  // const [result, setresult] = useState(false);

  // useEffect(() => {
  //   const validate = async () => {
  //     const result1 = await ValidateToken();
  //     setresult(result1[0]);
  //   };
  //   validate();
  // }, []);

  return (
    <Switch>
      {/* { */}
      {/* <Suspense fallback={<Spin indicator={antIcon} />}> */}
      {routes.map(route => {
        if (route.authorize) {
          return (
            <PrivateRoute
              authorized={result}
              key={route.path}
              keys={route.path}
              exact={route.exact}
              {...route}
              {...props}
            />
          );
        } else {
          return (
            <PublicRoute
              {...props}
              path={route.path}
              component={route.component}
              key={route.path}
              exact={route.exact}
              header={route.header}
              footer={route.footer}
            />
          );
        }
      })}
      {/* </Switch> */}
      {/* </Suspense> */}
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default withRouter(Router);

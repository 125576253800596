import React, { useEffect, useState } from 'react';
import callApi from '_utils/callApi';
import ListingDetails from 'pages/Homepage/listing_details';
import { Spin, Space } from 'antd';
import { Col, Row } from 'reactstrap';
import './style.scss';
const NotifiacationPanel = () => {
  const [listingmodal, setlistingmodal] = useState(false);
  const [responseFromApi, setResponseFromApi] = useState();
  const [notifications, setNotfications] = useState();

  const getNotifications = async () => {
    try {
      const result = await callApi(`/api/backend/v1/notifications?limit=40&page=1`, {
        method: 'GET',
      });
      console.log(result);
      setResponseFromApi(result.data);
      setNotfications(result.data.rows);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getNotifications('');
  }, []);

  return (
    <div className="notification">
      {!notifications && (
        <Space className="spinner" size="center">
          <Spin size="large" />
        </Space>
      )}
      {notifications?.map((datas, key) => (
        <div className="content" onClick={() => (window.location.href = `/${datas.slug}`)}>
          <div style={{ display: 'flex', cursor: 'pointer' }}>
            <div>
              {
                <img
                  style={{ width: '25px', height: '25px', borderRadius: '15px', margin: '3px' }}
                  src={datas.user.profileImage || 'https://www.gravatar.com/avatar/?d=mp'}
                />
              }
            </div>

            <div style={{ marginLeft: '5px' }}>
              <span className="notfy-name">{' ' + datas.user.username}</span> posted a
              <span className="notfy-content">
                {datas.type === 'post' ? ' Donation ' : ' Requirment '}
                <img style={{ width: '12px', height: '12px' }} src={datas.category.image} />
              </span>{' '}
              on {datas.city}
            </div>
          </div>

          <ListingDetails
            details={{
              full_address: datas.full_address,
              user: datas.user,
              phone: datas.phone,
              latitude: datas.latitude,
              longitude: datas.longitude,
              expired: datas.expired,
              expiredate: datas.expiredate,
            }}
            // details={details}
            listingmodal={listingmodal}
            setlistingmodal={setlistingmodal}
          />
        </div>
      ))}
    </div>
  );
};

export default NotifiacationPanel;

import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { RegisterSchema } from '_utils/Schemas';
import callApi from '_utils/callApi';
import { api } from '_constants/api';
import { getFormData } from '_utils';
import { message,Button } from 'antd';
import { registerUser } from 'services/signup';

const Register = ({ setstatus }) => {
  const [disabled, setdisabled] = useState(false);

  const handleRegister = async values => {
    const {c_password, remember,cpassword, ...sentingValues} = values;
    try {
      setdisabled(true);
      const res = await registerUser(sentingValues);
      setstatus('login');
      setdisabled(false);
    } catch (err) {
      console.log(err);
      setdisabled(false);
    }
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          username: '',
          email: '',
          c_password: '',
          password: '',
          remember: false,
          volunteer: false,
        }}
        validationSchema={RegisterSchema}
        onSubmit={handleRegister}
      >
        {({ handleSubmit, touched, errors, isSubmitting }) => (
          <Form className="login__form" handleSubmit={handleSubmit}>
            <p>Create Your Account</p>
            <p>
              <Field name="username" placeholder="Name/Business Name" type="text"></Field>
              {touched.username && errors.username ? (
                <div className="errormsg">{errors.username}</div>
              ) : (
                ''
              )}
            </p>
            <p>
              <Field name="email" placeholder="Email Address" type="email"></Field>
              {touched.email && errors.email ? <div className="errormsg">{errors.email}</div> : ''}
            </p>
            <p>
              <Field name="password" placeholder="Password" type="password"></Field>
              {touched.password && errors.password ? (
                <div className="errormsg">{errors.password}</div>
              ) : (
                ''
              )}
            </p>
            <p>
              <Field name="cpassword" placeholder="Retype Password" type="password"></Field>
              {touched.cpassword && errors.cpassword ? (
                <div className="errormsg">{errors.cpassword}</div>
              ) : (
                ''
              )}
            </p>
            <p style={{fontSize:"14px"}}>
              <Field name="volunteer" type="checkbox"></Field>
              Volunteer
              
                {' '}
                (by checking this box you are agreeing to receive alerts for posted donations / wanted items
                and in your neighborhood)
             
              {touched.volunteer && errors.volunteer ? (
                <div className="errormsg">{errors.volunteer}</div>
              ) : (
                ''
              )}
            </p>
            <p>
              <Field name="remember" type="checkbox"></Field>
              &nbsp; You agree with our{" "}
              <a onClick={() => (window.location.href = '/privacy-and-policy')}>Privacy Policy</a> and{" "} 
              <a target="_blank" target="_blank" rel="noopener noreferrer" onClick={() =>window.location.href='/terms-of-service-agreement'}>
                Terms and Conditions.
              </a>
              {touched.remember && errors.remember ? (
                <div className="errormsg">{errors.remember}</div>
              ) : (
                ''
              )}
            </p>
            <p>
              <Button htmlType="submit" loading={disabled} className="loginbutton">
                Create An Account
              </Button>
            </p>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Register;

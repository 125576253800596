import React from 'react';
import { isMobile } from 'react-device-detect';
import {Foodcan} from "../../index";

const Footer = ({ setscroll, messageCount }) => {

  const { status } = React.useContext(Foodcan);

  return (
    <div className="footer">
      {status && isMobile && (
          <a href="/message" className="zenDesk">
            <i className="fas fa-comment-dots fa-lg"></i>
            <span className="badge">{messageCount!==0?messageCount:''}</span>
          </a>
      )}
      {status && !isMobile && (
          <a href="/message" className="zenDesk1">
            <i className="fas fa-comment-dots fa-lg"></i>
            <span className="badge">{messageCount!==0?messageCount:''}</span>
          </a>
      )}
      <div className="footer__main">
        <div className="footer__left">
          <a href="https://twitter.com/Foodcan3" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
          <a
            href="https://www.facebook.com/Foodcanorg-107887734471537"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com/foodcan2021/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </div>
        <div className="footer__center">
          © 2020 <b>foodcan</b> All Rights Resevered.
          <a className="f-menu-color" onClick={() => (window.location.href = '/terms-of-service-agreement')}> Terms and Condtions</a>
          {' '}/ <a className="f-menu-color" onClick={() => (window.location.href = '/privacy-and-policy')}>Privacy Policy</a>
          {' '}/ <a className="f-menu-color" onClick={() => (window.location.href = '/contact-us')}>Contact Us</a>
        </div>
        <div className="footer__right">

          <button onClick={() => setscroll(Math.random())}>
            <i className="fa fa-arrow-up"></i> Back to Top
          </button>
        </div>
      </div>
    </div>
  );
};

export default Footer;

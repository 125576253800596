import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Login from './login';
import Register from './register';
import { Modal } from 'antd';

const SignUp = ({ setmodal, onClose }) => {
  const [modalvisible, setmodalvisible] = useState(true);
  const [status, setstatus] = useState('login');
  return (
    <Modal
      visible={modalvisible}
      width={400}
      onCancel={() => {
        onClose(false);
        setmodalvisible(false);
        setmodal(false);
      }}
      footer={false}
      destroyOnClose={true}
    >
      <div className="login">
        <div className="login__title">
          <label onClick={() => setstatus('login')}>Log In</label>
          <label onClick={() => setstatus('register')}>Register</label>
        </div>
        <br />
        {status == 'login' ? <Login /> : <Register setstatus={setstatus} />}
      </div>
    </Modal>
  );
};

export default SignUp;

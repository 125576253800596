import React, { useState,useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { GoogleLogin } from 'react-google-login';
import FacebookLoginWithButton from 'components/SocialMedia/facebook/facebook-with-button';
import { LoginSchema, ForgotPasswordSchema } from '_utils/Schemas';
import { api } from '_constants/api';
// import { getFormData } from '_utils';
import callApi from '_utils/callApi';
import { message,Button } from 'antd';
import { loginUserWithGoogle, loginUserWithFacebook } from 'services/login';
// import FacebookLogin from 'react-facebook-login';
import store from "store";

const Login = () => {
  const [disabled, setdisabled] = useState(false);
  const [disabled1, setdisabled1] = useState(false);
  const [loginform, setloginform] = useState(true);
  const [changepasswordform, setchangepasswordform] = useState(false);
  const innerForm = useRef(null);
  async function loginUserWithPassword(values) {
    setdisabled(true);
    try {
      //   const formData = getFormData(values);
      const options = {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization':'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJpYXQiOjE2MDQ2MzY1MTAsImV4cCI6MTk2OTYzNjUxMH0.nZ1k-azTjiy1joYV0T8zyLnwtt3mCZt-QoEORNZRjIE'
        },
      };
      const response = await callApi('/api/backend/v1/login', options);

      if (response && response.token) {
        window.localStorage.setItem('token', response.token);
        window.localStorage.setItem('data', JSON.stringify(response));
        store.set('User', response.data);

        window.localStorage.setItem('loginType', response.data.loginType);
        // window.history.push('/');
        window.location.href = '/';

        // setTimeout(() => {
        //   window.location.href = '/';
        // }, 2000);
      }
      setdisabled(false);
      return null;
    } catch (error) {
      console.log('error', error);
      message.error(error.message);
      setdisabled(false);

      return { error: error.message };
    }
    return {};
  }

  async function handleforgetpassword(values) {
    console.log('values', values);
    setdisabled1(true);
    try {
      //   const formData = getFormData(values);
      //   console.log("formDatta",formData)
      const options = {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization':'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJpYXQiOjE2MDQ2MzY1MTAsImV4cCI6MTk2OTYzNjUxMH0.nZ1k-azTjiy1joYV0T8zyLnwtt3mCZt-QoEORNZRjIE'
        },
      };
      const response = await callApi('/api/backend/v1/forgotPassword', options);
      console.log('a response', response);
      setdisabled1(false);
      if (response && response.success) {
        message.success('Please check your email');
        innerForm.current.resetForm();
        // setTimeout(() => {
        //   window.location.href = '/';
        // }, 1000);
      } else if (response && !response.success) {
        message.error(response.message);
      }
      return null;
    } catch (error) {
      console.log('error', error);
      message.error(error.message);
      setdisabled1(false);

      return { error: error.message };
    }
    return {};
  }
  const handleLogin = async values => {
    try {
      const result = await callApi(api.login, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      window.localStorage.setItem('token', result.token);
      message.success('LOGGED IN SUCCESSFULLY');
    } catch (err) {
      console.log(err);
      message.error('Please try again');
    }
  };
  return loginform ? (
    <>
      {' '}
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: '',
          password: '',
          remember: false,
        }}
        validationSchema={LoginSchema}
        onSubmit={loginUserWithPassword}
      >
        {({ handleSubmit, touched, errors , isSubmitting }) => (
          <Form className="login__form" onSubmit={handleSubmit}>
            <p>Log In Your Account</p>
            <p>
              <Field name="email" placeholder="Email" type="text"></Field>
              {touched.email && errors.email ? <div className="errormsg">{errors.email}</div> : ''}
            </p>
            <p>
              <Field name="password" placeholder="Password" type="password"></Field>
              {touched.password && errors.password ? (
                <div className="errormsg">{errors.password}</div>
              ) : (
                ''
              )}
            </p>
            <p>
              <Field name="remember" type="checkbox"></Field>
              Remember
            </p>
            <p>
              <Button loading={disabled} htmlType="submit" className="loginbutton">
                Log In
              </Button>
            </p>
          </Form>
        )}
      </Formik>
      <a
        onClick={() => {
          setloginform(false);
          setchangepasswordform(true);
        }}
        style={{ marginBottom: 10 }}
      >
        Forgot password? Click Here{' '}
      </a>
      <label className="loginwith">Or Log In With</label>
      <br />
      <div className="button_group">
        {/* <FacebookLogin
          appId="3547749868647405"
          // autoLoad={true}
          fields="name,email,picture"
          callback={loginUserWithFacebook}
          cssClass="log-facebook"
          icon="fa-facebook-f"
        /> */}
        <FacebookLoginWithButton
          cssClass="log-facebook"
          appId="3547749868647405"
          fields="name,email,picture"
          // autoLoad
          callback={loginUserWithFacebook}
          icon="fa-facebook-f"
        />

        <GoogleLogin
          // className="log-google p-2 mb-2 text-center rounded letter-tras"
          // clientId="891830764310-e0qh1p7236786tpvarco5ktqfvbr1v20.apps.googleusercontent.com"
          // clientId="359928557903-lq8m90uqsprt8jcr3h01qtmas5eu5697.apps.googleusercontent.com"
          clientId="970951480466-gdbjmhkgtftk1f9jeak44thj0gufd4s0.apps.googleusercontent.com"
          buttonText="Login"
          onSuccess={loginUserWithGoogle}
          onFailure={loginUserWithGoogle}
          cookiePolicy="single_host_origin"
          icon={'fa-google-plus'}
        />
      </div>
    </>
  ) : (
    <>
      {' '}
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: '',
        }}
        validationSchema={ForgotPasswordSchema}
        innerRef={innerForm}
        onSubmit={handleforgetpassword}
      >
        {({ handleSubmit, touched, errors }) => (
          <Form className="login__form" onSubmit={handleSubmit}>
            <p>Forgot Password</p>
            <p>
              <Field name="email" placeholder="Email" type="text"></Field>
              {touched.email && errors.email ? <div className="errormsg">{errors.email}</div> : ''}
            </p>
            <p>
              <Button htmlType="submit" loading={disabled1} type="submit" className="loginbutton">
                FORGOT PASSWORD
              </Button>
            </p>
          </Form>
        )}
      </Formik>
      <a
        onClick={() => {
          setloginform(true);
          setchangepasswordform(false);
        }}
        style={{ marginBottom: 10 }}
      >
        Already a user? Click Here{' '}
      </a>
    </>
  );
};
export default Login;

export const MessageState = {
    mobileListScreen: true,
    initalLoading:true,
    error: false,
    payload: [],
    message: '',
    uuid:'',
    receiverAvatar:'',
    receiverDisplayName:'',
    userList:'',
    userListModal:false,
    setRecevierUUID:''
  };

import 'toasted-notes/src/styles.css';
import callApi from '_utils/callApi';
import { getFormData } from '_utils';
import toaster from 'toasted-notes';
import { message } from 'antd';

export async function sendOTPtoRegister(phone) {
  console.log('values', phone);
  try {
    //   const formData = getFormData(values);
    //   console.log("formDatta",formData)
    const options = {
      method: 'GET',
      // body: JSON.stringify(values),
      // headers: {
      //     'Content-Type': 'application/json',
      //     // 'Authorization':'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJpYXQiOjE2MDQ2MzY1MTAsImV4cCI6MTk2OTYzNjUxMH0.nZ1k-azTjiy1joYV0T8zyLnwtt3mCZt-QoEORNZRjIE'
      //   },
    };
    const a = await callApi(`/api/backend/v1/user/signup/mvalidate/${Number(phone)}`, options);
    console.log('a response', a);
    if (a && a.data) return a.data;
    return null;
  } catch (error) {
    console.log('error', error);
    toaster.notify(error.message, { position: 'top-right' });
    return { error: error.message };
  }
  return {};
}

export async function registerUser(values) {
  console.log('values', values);
  try {
    const formData = getFormData(values);
    //   console.log("formDatta",formData)
    const options = {
      method: 'POST',
      // body: JSON.stringify(values),
      // headers: {
      //     'Content-Type': 'application/json',
      //     // 'Authorization':'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJpYXQiOjE2MDQ2MzY1MTAsImV4cCI6MTk2OTYzNjUxMH0.nZ1k-azTjiy1joYV0T8zyLnwtt3mCZt-QoEORNZRjIE'
      //   },
      body: formData,
    };
    const a = await callApi('/api/backend/v1/register', options);
    console.log('a response', a);
    if (a && a.data) {
      message.success('Registered successfully');
      return a.data;
    }
    return null;
  } catch (error) {
    console.log('error', error);
    message.error(error.message);
    return { error: error.message };
  }
  return {};
}

import React, { useState, useContext, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { Modal, Rate, message } from 'antd';
import { Foodcan } from 'index';
import callApi from '_utils/callApi';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const ListingDetails = ({ listingmodal, setlistingmodal, details }) => {
  const { status, user_id: userId } = useContext(Foodcan);
  const [rating, setrating] = useState(0);
  const [disabled, setdisabled]=useState(false);
  const shareUrl = `https://www.foodcan.riolabz.com/${details.slug}`

  const title = details.title;
  const submitRating = async values => {
    setdisabled(true);
    try {
      const options = {
        method: 'POST',
        body: JSON.stringify({ rating: values, userId: details.user.id }),
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const result = await callApi('/api/backend/v1/user_review', options);
      if (result) {
        setrating(values);
        message.success('Thankyou for your rating');
        setdisabled(false);
      }
      else {
        setdisabled(false);
      }

    } catch (err) {
      message.error('Please try again');
      setdisabled(false);
    }
  };

  useEffect(() => {
    callApi(`/api/backend/v1/user_review?r_user=${details.user.id}`)
      .then(result => {
        setrating(result.data.rating);
        console.log(result);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
    return (
      <Modal
        visible={listingmodal}
        width={500}
        onCancel={() => {
          setlistingmodal(false);
        }}
        footer={false}
        destroyOnClose={true}
        title={details.title}
        style={{ fontSize: 20 }}
        className={'listingmodal'}
      >
        <div className="listingdetails">
          {/* <div className="listingdetails__phone">
            <label>Name</label>
            <label>{details.user.firstName + ' ' + details.user.lastName || 'N/A'} </label>
          </div> */}
          <div className="listingdetails__phone">
            <label>Phone</label>
            <label>{details.phone || 'N/A'} </label>
          </div>
          <div className="listingdetails__address">
          <label>Description</label>
          <label>{details.description || 'N/A'}</label>
        </div>
          <div className="listingdetails__address">
            <label>Address</label>
            <label>{details.full_address || 'N/A'}</label>
          </div>
          {status && (
            <div className="listingdetails__rating">
              <label>Charity rating</label>
              <label>
                <Rate disabled={disabled} onChange={submitRating} value={rating} />
              </label>
            </div> 
          )}
          <div className="listingdetails__address">
            <label>Share it on</label>
            <label>
          <div>
          <FacebookShareButton
            url={shareUrl}
            quote={title}
            style={{padding:"10px"}}
          >
         <i class="fab fa-facebook-f"></i>
          </FacebookShareButton>

          <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
            style={{padding:"10px"}}

          >
         <i class="fab fa-whatsapp"></i>
          </WhatsappShareButton>

          <EmailShareButton
            url={shareUrl}
            subject={title}
            body=""
            style={{padding:"10px"}}

          >
            <i class="fas fa-envelope-square"></i>
          </EmailShareButton>

          <TelegramShareButton
            url={shareUrl}
            title={title}
            style={{padding:"10px"}}

          >
         <i class="fab fa-telegram"></i>
          </TelegramShareButton>

          <TwitterShareButton
            url={shareUrl}
            title={title}
            style={{padding:"10px"}}

          >
    <i class="fab fa-twitter"></i>
          </TwitterShareButton>
          </div></label>
          </div>


          <div className="listingdetails__direction">
            <button>
              <a
                style={{ color: 'white' }}
                target="_blank"
                href={`http://maps.google.com?q=${details.latitude},${details.longitude}`}
              >
                Get Directions
              </a>
            </button>
          </div>
        </div>
      </Modal>
    );
};

export default ListingDetails;
